import { useStaticQuery, graphql } from 'gatsby';

const useMembershipPage = () => {
    const { sanityPageMembership: membershipPage } = useStaticQuery(graphql`
        fragment MenuItem on SanityMenuItem {
            title
            external
            externalUrl
            page {
                slug {
                    current
                }
            }
        }
        query {
            sanityPageMembership {
                bannerImage {
                    alt
                    image {
                        ...ImageWithPreview
                    }
                }
                bannerTitle {
                    en
                    se
                }
                boxDescription {
                    en
                    se
                }
                boxLinkTitle {
                    en
                    se
                }
                boxTitle {
                    en
                    se
                }
                articlesTitle {
                    en
                    se
                }
                articlesDescription {
                    en {
                        _rawContent
                    }
                    se {
                        _rawContent
                    }
                }
                articlesList {
                    _createdAt
                    publishDate
                    title
                    readingTime
                    slug {
                        current
                    }
                    language {
                        name
                        hreflang
                    }
                    image {
                        defaultImage {
                            image {
                                ...ImageWithPreview
                            }
                            alt
                        }
                    }
                    video {
                        teaserVideoPreview {
                            image {
                                ...ImageWithPreview
                            }
                            alt
                        }
                        teaserVideo {
                            url
                        }
                    }
                    _rawContent
                }
                membershipsList {
                    _key
                    title {
                        en
                        se
                    }
                    subTitle {
                        en
                        se
                    }
                    advantagesList {
                        _key
                        en
                        se
                    }
                    isFavorite
                    linkUrl
                    price
                    priceMention {
                        en
                        se
                    }
                }
                graphTitle {
                    en
                    se
                }
                graphMembersMax
                graphMembersNow
                graphMembersRecord
                graphMembersRecordYear
                faqTitle {
                    se
                    en
                }
                faqSubtitle {
                    se
                    en
                }
                faqLink {
                    se {
                        ...MenuItem
                    }
                    en {
                        ...MenuItem
                    }
                }
            }
        }
    `);

    return membershipPage;
};

export default useMembershipPage;
