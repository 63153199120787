// extracted by mini-css-extract-plugin
export var newsItem = "item-module--newsItem--ZbjiZ";
export var newsItemHighlighted = "item-module--newsItemHighlighted--mv6w1";
export var newsItemMatch = "item-module--newsItemMatch--xwc2f";
export var newsItemVideo = "item-module--newsItemVideo--CGFCf";
export var newsItem__img = "item-module--newsItem__img--4FT1z";
export var newsItem__imgCustomSize = "item-module--newsItem__imgCustomSize--ONeIn";
export var newsItem__imgNotCustomSize = "item-module--newsItem__imgNotCustomSize--o9JXQ";
export var newsItem__score = "item-module--newsItem__score--Vu9zq";
export var newsItem__score__item = "item-module--newsItem__score__item--CSkKW";
export var newsItem__text = "item-module--newsItem__text--o6Vos";
export var newsItem__videoModal = "item-module--newsItem__videoModal--x7aCo";