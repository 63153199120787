import React from 'react';

import CustomLink from 'components/utils/CustomLink';

const LinkFromMenuItem = ({ item, cssClass }) => {
    if (item.page) {
        return (
            <CustomLink
                className={cssClass}
                to={item.page.slug.current !== '/' ? `/${item.page.slug.current}` : '/'}
                target={item.external ? '_blank' : '_self'}
                isTranslated={false}
            >
                {item.title}
            </CustomLink>
        );
    }

    if (!item.page && item.externalUrl) {
        return (
            <a
                className={cssClass}
                href={item.externalUrl}
                rel="noreferrer"
                target={item.external ? '_blank' : '_self'}
            >
                {item.title}
            </a>
        );
    }
    return null;
};

export default LinkFromMenuItem;
