import React, { useContext } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import * as style from 'styles/components/membership/membership.module.scss';
import { LangContext } from 'context/LangContext';
import useMembershipPage from 'data/queries/useMembershipPage';
import NewsItem from 'components/news/Item';
import Banner from 'components/misc/Banner';
import LinkFromMenuItem from 'components/utils/LinkFromMenuItem';
import TextEditorRender from 'components/utils/TextEditorRender';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';

const Membership = () => {
    const { lang } = useContext(LangContext);
    const data = useMembershipPage();

    const graphBarRecordSize = data.graphMembersRecord / data.graphMembersMax * 100;
    const graphBarNowSize = data.graphMembersNow / data.graphMembersMax * 100;

    const graphBarRecordSizeStyle = { width: `${graphBarRecordSize}%` };
    const graphBarNowSizeStyle = { width: `${graphBarNowSize}%` };

    // Prevent the text from overlapping the screen if the bar is empty/full
    const graphBarTextPositionMobile = (barSize) => {
        if (barSize < 25) {
            return { transform: `translateX(calc(50% - 10px))`, textAlign: 'left' };
        }

        if (barSize > 80) {
            return { transform: `translateX(calc(-50% + 10px))`, textAlign: 'right' };
        }

        return null;
    };

    return (
        <div className={style.membershipPage}>
            {data.bannerImage && data.bannerTitle && (
                <Banner
                    image={data.bannerImage.image}
                    title={data.bannerTitle[lang.hreflang]}
                />
            )}

            {data.boxLinkTitle && data.boxTitle && data.boxDescription && (
                <div className={style.box}>
                    <div className={style.box__logo}>
                        <StaticImage
                            src="../images/hammarbyLogoWhite2.png"
                            alt="Hammarby Fotboll"
                        />
                    </div>

                    <div>
                        <span className={style.box__surtitle}><FormattedMessage id="membership.fan" /></span>
                        <p className={style.box__title}>{data.boxLinkTitle[lang.translationKey]}</p>
                        <p>{data.boxDescription[lang.translationKey]}</p>
                    </div>

                    <div className={style.box__link}>
                        <a className="cta-yellow" href="#membership">{data.boxLinkTitle[lang.translationKey]}</a>
                    </div>
                </div>
            )}

            <section className="wrapper">
                <div className="wrapper-s">
                    <h2 className="title-lg title-sm-mobile bold text-center">
                        {data.articlesTitle[lang.translationKey]}
                    </h2>

                    <div className={style.articles__intro}>
                        {data.articlesDescription && data.articlesDescription[lang.translationKey] && (
                            <TextEditorRender content={data.articlesDescription[lang.translationKey]._rawContent} />
                        )}
                    </div>
                </div>

                <div className={style.articles__container}>
                    {data.articlesList
                        .filter(article => article.language.hreflang === lang.translationKey)
                        .map(article => (
                            <div key={article._createdAt}>
                                <NewsItem item={article} />
                            </div>
                        ))
                    }
                </div>
            </section>

            <section id="membership" className={`${style.memberships} wrapper`}>
                <h2 className="title-lg title-sm-mobile bold text-center">
                    <FormattedMessage id="membership.clubMembership" />
                </h2>

                <div className={style.memberships__container}>
                    {data.membershipsList.length !== 0 && data.membershipsList.map(card => (
                        <div key={card._key} className={style.membershipCard}>
                            {card.isFavorite && (
                                <span className={style.membershipCard__fav}>
                                    <FormattedMessage id="membership.favorite" />
                                </span>
                            )}

                            <h3 className="title-sm bold center">{card.title[lang.translationKey]}</h3>
                            <p className="center text-reversed-light">{card.subTitle[lang.translationKey]}</p>

                            <ul className={style.membershipCard__advantages}>
                                {card.advantagesList.map(item => (
                                    <li key={item._key} className={style.membershipCard__advantage}>
                                        <i className="ico-check" aria-hidden />
                                        <span>{item[lang.translationKey]}</span>
                                    </li>
                                ))}
                            </ul>

                            <div className={style.membershipCard__priceContainer}>
                                <span className="uppercase medium text-lg">
                                    <FormattedMessage id="membership.priceCurrency" />
                                </span>
                                <span className={style.membershipCard__price}>{card.price}</span>
                                <p className="text-reversed-light text-xs">
                                    {card.priceMention[lang.translationKey]}
                                </p>
                            </div>

                            <a className={`${style.membershipCard__link} cta-green`}
                               href={card.linkUrl} target="_blank" rel="noreferrer" aria-label={getFormattedMessage('membership.choose', lang)}>
                                <FormattedMessage id="membership.choose" />
                            </a>
                        </div>
                    ))}
                </div>
            </section>

            <section className="wrapper">
                <div className={style.graph}>
                    <h2 className="title-lg title-sm-mobile bold text-center">
                        {data.graphTitle[lang.translationKey]}
                    </h2>

                    <div className={`${style.graph__container} wrapper-s`}>
                        <div className={style.graph__fullBar} />

                        <div className={style.graph__recordBar} style={graphBarRecordSizeStyle}>
                            <p className={`${style.graph__barInfo} ${style.graph__barInfo__bottom}`}>
                                <span style={graphBarTextPositionMobile(graphBarRecordSize)}>
                                    <b><FormattedMessage id="membership.graphRecord" /></b>
                                    <br />
                                    {data.graphMembersRecord} ({data.graphMembersRecordYear})
                                </span>
                            </p>
                        </div>

                        <div className={style.graph__currentBar} style={graphBarNowSizeStyle}>
                            <p className={`${style.graph__barInfo} ${style.graph__barInfo__top}`}>
                                <span style={graphBarTextPositionMobile(graphBarNowSize)}>
                                    <b><FormattedMessage id="membership.graphNow" /></b>
                                    <br />
                                    {data.graphMembersNow}
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            <section className={style.faq}>
                <h2 className="title-lg title-sm-mobile bold text-center">
                    {data.faqTitle[lang.translationKey]}
                </h2>

                <p className={style.faq__subtitle}>{data.faqSubtitle[lang.translationKey]}</p>

                <LinkFromMenuItem item={data.faqLink[lang.translationKey]} cssClass="cta-yellow" />
            </section>
        </div>
    );
};

export default Membership;
