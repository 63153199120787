import React, { useState, useEffect, useContext } from 'react';
import ImageGatsby from 'gatsby-plugin-sanity-image';

import * as style from 'styles/components/news/item.module.scss';
import { LangContext } from 'context/LangContext';
import { RoutesContext } from 'context/RoutesContext';
import CustomLink from 'components/utils/CustomLink';
import EmbedVideoPlayer from 'components/utils/EmbedVideoPlayer';
import Modal from 'components/utils/Modal';
import returnDate from 'utils/returnDate';

const NewsItem = ({ item, customWidth, customHeight, isHighlighted = false }) => {
    const { lang } = useContext(LangContext);
    const { routes } = useContext(RoutesContext);

    const [href, setHref] = useState('');
    const [videoPlaying, setVideoPlaying] = useState(false);

    useEffect(() => {
        if (routes && routes.length !== 0) {
            setHref(
                `/${
                    routes.find((r) => r.node.name === 'newsList' && r.node.lang === lang.hreflang)
                        .node.path
                }/${
                    item.slug.current
                }`,
            );
        }
    }, [lang, routes, item.slug]);

    const isMatch = !!item.match?.id;
    const isVideo = !!item.video?.teaserVideo?.url;

    const imgWidth = customWidth || 420;
    const imgHeight = customWidth || 254;

    // Use in priority teaserImage, then use defaultImage
    // eslint-disable-next-line no-nested-ternary
    let image = item.image?.teaserImage?.image?.asset
        ? item.image.teaserImage.image
        : item.image?.defaultImage?.image?.asset
            ? item.image.defaultImage.image
            : null;

    if (isVideo && item?.video?.teaserVideoPreview?.image) {
        image = item.video.teaserVideoPreview.image;
    }

    const renderMatchScore = () => {
        if (!isMatch) {
            return null;
        }
        return (
            <div className={style.newsItem__score}>
                <div className={style.newsItem__score__item}>
                    <p className="text-bold">{item.match.localScore}</p>
                    <p className="text-medium">{item.match.local.shortName.slice(0, 4)}</p>
                </div>
                <div className={style.newsItem__score__item}>
                    <p className="text-bold">{item.match.externalScore}</p>
                    <p className="text-medium">{item.match.external.shortName.slice(0, 4)}</p>
                </div>
            </div>
        );
    };

    const newsContainerClasses = `
        ${style.newsItem} 
        ${isVideo ? style.newsItemVideo : ''}
        ${isMatch ? style.newsItemMatch : ''}
        ${isHighlighted ? style.newsItemHighlighted : ''}
        ${videoPlaying ? 'playing' : ''}
    `.trim();

    return (
        <div className={newsContainerClasses}>
            {!isVideo && image?.asset && (
                <div className={style.newsItem__img}>
                    <CustomLink to={href}>
                        {/* Custom size is only for desktop */}
                        {customWidth && customHeight && (
                            <ImageGatsby
                                {...image}
                                width={customWidth}
                                height={customHeight}
                                alt={item.image?.defaultImage?.alt ?? ''}
                                className={style.newsItem__imgCustomSize}
                            />
                        )}

                        <ImageGatsby
                            {...image}
                            width={imgWidth}
                            height={imgHeight}
                            alt={item.image?.defaultImage?.alt ?? ''}
                            className={style.newsItem__imgNotCustomSize}
                        />
                    </CustomLink>
                </div>
            )}

            {isVideo && image?.asset && (
                <>
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <div
                        className={style.newsItem__img}
                        onClick={() => {setVideoPlaying(true);}}
                    >
                        <ImageGatsby
                            {...image}
                            width={imgWidth}
                            height={imgHeight}
                            alt={item.image?.defaultImage?.alt ?? ''}
                        />
                        <i aria-hidden className="ico-play" />
                    </div>

                    {videoPlaying && (
                        <Modal
                            className={style.newsItem__videoModal}
                            show={videoPlaying}
                            onClose={() => setVideoPlaying(false)}
                        >
                            <EmbedVideoPlayer
                                url={`${item.video.teaserVideo.url}?autoplay=1`}
                                title={item.title}
                            />
                        </Modal>
                    )}
                </>
            )}

            {!isVideo && !image?.asset &&
                <div className={style.newsItem__img} />
            }

            {!isHighlighted && item.showResult && renderMatchScore()}

            <div className={style.newsItem__text}>
                <CustomLink to={href}>
                    {/* Render score inside text only for highlighted news */}
                    {isHighlighted && item.showResult && renderMatchScore()}
                    <p className="text-xs text-medium text-darker">{returnDate({ item, lang })}</p>
                    <h3 className="text-lg text-semibold">{item.title}</h3>
                </CustomLink>
            </div>
        </div>
    );
};

export default NewsItem;
